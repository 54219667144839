import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";

import Traec from "traec";
import { getPathChildren } from "traec/utils/nodes";

import { BSBtn, BSCard, BSModal } from "traec-react/utils/bootstrap";

import { ErrorBoundary } from "traec-react/errors";
import ReportRowErrorBoundary from "./error";
import { loading, Spinner } from "traec-react/utils/entities";
import { getProjectProps, loadConvFacts } from "AppSrc/project/utils";
import ReportCategoryItem from "./reportCategoryItem";

import { alertSuccess, confirmProceed } from "traec-react/utils/sweetalert";
import { getCommitStatus } from "AppSrc/project/commits/row";
import { reportingPeriodText, yearlyReportingPeriodText } from "AppSrc/project/report/utils";
import { isAllRequiredSubmitted, Submit } from "AppSrc/project/report/submit";
import {
  getChildMetricScores,
  getScoreValuesByBaseMetric,
  hasDiff,
  pathHasInputs,
  setInitialConversions,
} from "./utils";
import Octicon from "react-octicon";
import { Tooltip } from "react-tippy";

import { SetMetaApportion } from "AppSrc/forms/apportion";
import { Redirect } from "react-router";
import TipsSpinner from "../../utils/spinners/tipsSpinner";
import WizardModal from "storybook-dashboard/components/modal/wizardModal.js";
import { editMeta, SetMetaDataFields } from "../../forms/meta";
import Im from "immutable";
import useApi from "storybook-dashboard/utils/fetching";

/* ###### ACTIONS ###### */

const updatePatchCommit = (props, body) => {
  let { trackerId, refId, commitId } = props;
  let fetch = new Traec.Fetch("tracker_ref_commit", "patch", { trackerId, refId, commitId });
  fetch.updateFetchParams({ body });
  fetch.dispatch();
};

/* ###### COMPONENTS ###### */

const STATUS_PROPS = {
  notstarted: { name: "alert", className: "text-danger" },
  incomplete: { name: "alert", className: "text-warning" },
  complete: { name: "check", className: "text-success" },
};

const is_sbcc = location.hostname.startsWith("sbcc");
const is_cif = location.hostname.startsWith("cif");
const renderReportPeriod = is_cif ? yearlyReportingPeriodText : reportingPeriodText;

function ReportPeriodSelection(props) {
  let {
    currentReportingPeriod: reportingPeriod,
    projectReportingPeriods: reportingPeriods,
    disableInputs,
    setSelectedPeriod,
  } = props;

  let currentReportingPeriod = reportingPeriod?.get("uid");
  const [selectedId, setSelectedId] = useState(currentReportingPeriod || "");
  const initialReportingPeriodRef = useRef(currentReportingPeriod);

  let options = (reportingPeriods || Traec.Im.Map())
    .toList()
    .filter(
      (i) =>
        // If a reporting period has commits (meaning it has a submitted report) do not render the option UNLESS it is: 1. the previously selected reporting period or 2. the current reporting period. These both appear with commits when they strictly shouldn't.
        (i?.get("commits") || Traec.Im.List()).size == 0 ||
        i.get("uid") == currentReportingPeriod ||
        i.get("uid") == initialReportingPeriodRef.current
    )
    .sortBy((i) => i.get("startDate"))
    .map((rp, i) => {
      let _id = rp.get("uid");
      return (
        <option key={i} value={_id}>
          {renderReportPeriod(rp)}
        </option>
      );
    });

  useEffect(() => {
    // This useEffect will update the text displayed on the main page "Reporting for:..."
    const period = reportingPeriods?.find((p) => p.get("uid") === currentReportingPeriod);
    if (period) {
      setSelectedPeriod(renderReportPeriod(period));
    }
  }, [reportingPeriod, reportingPeriods]);

  const handleChange = (event) => {
    const { value, selectedIndex, options } = event.target;
    const text = options[selectedIndex].text;
    setSelectedId(value);
    setSelectedPeriod(text);

    updatePatchCommit(props, { reporting_period: value });
  };

  return (
    <select
      disabled={disableInputs}
      className="form-control form-control-sm selected-reporting-period"
      style={{ width: "250px", display: "inline-block" }}
      value={selectedId}
      onChange={(e) => {
        handleChange(e);
      }}
    >
      {options}
    </select>
  );
}

function CategoryTabLabel({ name, _id, show, isSectionComplete, disableInputs, hasInputs }) {
  // Get the properties of the icon that we will use
  let section_status = "complete";
  let tooltipText = "You have marked this section as complete";
  if (isSectionComplete) {
    section_status = "complete";
    tooltipText = "You have marked this section as complete";
  } else {
    if (hasInputs) {
      section_status = "incomplete";
      tooltipText = "You have started entering data but are yet to mark the section as complete";
    } else {
      section_status = "notstarted";
      tooltipText = "You have not started completing this section";
    }
  }
  let status = STATUS_PROPS[section_status] || {};
  let iconName = status.name || "alert";
  let iconClass = status.className || "text-warning";

  return (
    <a className={`nav-item nav-link ${show ? "active" : ""}`} data-toggle="tab" href={`#${_id}`}>
      <Tooltip
        animateFill={false}
        html={
          <div className="text-left">
            <p>{tooltipText}</p>
          </div>
        }
      >
        {name} <Octicon name={iconName} className={iconClass} />
      </Tooltip>
    </a>
  );
}

function NavButton({ tabDetails, index, step, label }) {
  let toDetails = index + step >= 0 ? tabDetails.get(index + step) : null;
  //console.log("RENDERING NAV BUTTON", index, label, step, toDetails, tabDetails.toJS())
  if (!toDetails) {
    return null;
  }
  return (
    <button
      className="btn btn-sm btn-outline-primary ml-2"
      onClick={(e) => {
        // Use jQuery to emulate pressing next link in the tab
        let current = jQuery(".nav-tabs > .active");
        let next = step > 0 ? current.next("a") : current.prev("a");
        next.trigger("click");
      }}
    >
      {label}
    </button>
  );
}

function NavButtons(props) {
  return (
    <ErrorBoundary>
      <div className="float-right m-0">
        <NavButton {...props} step={-1} label="< Previous" />
        <NavButton {...props} step={1} label="Next >" />
      </div>
      <div style={{ clear: "both" }} />
    </ErrorBoundary>
  );
}

function CheckCategoryComplete(props) {
  let { setSectionComplete, categoryPath, isSectionComplete, disableInputs } = props;
  return (
    <ErrorBoundary>
      <div className="form-check float-right mb-2 mr-2">
        <input
          type="checkbox"
          className="form-check-input align-middle"
          id="categoryComplete"
          disabled={disableInputs}
          checked={isSectionComplete}
          onChange={(e) => setSectionComplete(categoryPath, !isSectionComplete)}
        />
        <label className="form-check-label" htmlFor="categoryComplete">
          <b className="text-danger">*</b>
          <b>Mark section as complete</b>
        </label>
      </div>
      <div style={{ clear: "both" }} />
    </ErrorBoundary>
  );
}

function CategoryTabContent(props) {
  let { _id, index, tabDetails, show } = props;

  return (
    <div id={_id} className={`tab-pane fade ${show ? "show active" : ""}`}>
      <CategoryTable {...props} asFullTable={true} hideTitleRow={true} />
      <CheckCategoryComplete {...props} />
      <NavButtons tabDetails={tabDetails} index={index} />
    </div>
  );
}

const MetaFieldsModalBody = ({ commit, modalId }) => {
  return (
    <div className="card shadow p-3 my-5 bg-white rounded border-0">
      <h3 className="my-3 p-2 font-weight-bold">Company information</h3>
      <br />
      <SetMetaDataFields
        disabled={(commit?.getInPath("status.name") || "").startsWith("OK")}
        hideAdmin={true}
        //hideSave={true}
        //saveOnBlur={true}
        saveMetaFetchProps={{
          handler: "tracker_ref_commit",
          method: "patch",
          params: {
            trackerId: commit.get("tracker"),
            refId: commit.get("ref"),
            commitId: commit.get("uid"),
          },
          successHook: (data) => {
            // if (revalidate) {
            //   revalidate();
            // }
            let _meta = Traec.Im.fromJS(data || {})?.get("meta_json");
            console.log("Validating saved meta-data", _meta?.toJS());
            if (isAdditionalMetaComplete(_meta)) {
              //console.log("Meta data is complete, calling handleMetaComplete");
              handleMetaComplete(_meta, modalId);
            } else {
              //console.log("Meta data is not complete. Popping up modal alert.");
              alertSuccess({
                title: "Company information not complete",
                text: "You must complete all fields in the company information before you can submit this report",
                iconType: "error",
              });
            }
          },
        }}
        metaJson={commit.get("meta_json")}
        onClick={() => $(`#${modalId}`).modal("hide")}
      />
    </div>
  );
};

function MetaFieldsReportComponent(props) {
  let { categoryTrees, commit } = props;

  if (!categoryTrees || categoryTrees.length === 0) {
    return null;
  }

  let modalId = "MetaFieldsReportComponentModal";

  const metaComplete = isAdditionalMetaComplete(commit?.get("meta_json"));

  if (is_sbcc) return null;

  return (
    <ErrorBoundary>
      <WizardModal
        hideClose={false}
        id={modalId}
        fullWidth={true}
        body={
          <ErrorBoundary>
            <div className="container-lg m-3">
              <button className="btn btn-link text-muted" onClick={() => $(`#${modalId}`).modal("hide")}>
                <p
                  className="text-muted text-underline mb-5"
                  style={{ cursor: "pointer", textDecoration: "underline" }}
                >
                  <Octicon name="chevron-left" />
                  Back to main reporting page
                </p>
              </button>
              <MetaFieldsModalBody commit={commit} modalId={modalId} />
            </div>
          </ErrorBoundary>
        }
      />
      <div className="container shadow rounded my-4">
        <div className="row p-3 m-3 justify-content-between">
          <div className="row">
            <h5 className="h5">Step 2/4</h5>
            <span className={`text-muted align-text-bottom mt-1 ml-2 `}>
              <Tooltip html={metaComplete ? "Complete" : "Not complete"} animateFill={false}>
                <Octicon
                  name={metaComplete ? "verified" : "unverified"}
                  className={`${metaComplete ? "text-success" : "text-warning"}`}
                />
              </Tooltip>
              <span className={`text-muted align-text-bottom mt-1 ml-2`}>Company information</span>
            </span>
          </div>

          <button
            className={`btn btn-sm font-weight-bold ${metaComplete ? "btn-success" : "btn-warning"}`}
            onClick={() => $(`#${modalId}`).modal("show")}
          >
            <u>{metaComplete ? "Complete" : "To do"}</u>
          </button>
        </div>
      </div>
    </ErrorBoundary>
  );
}

const CarbonReportModalBody = ({ tabLabels, tabContents }) => {
  return (
    <div className="card shadow p-3 bg-white rounded border-0">
      <h3 className="my-3 p-2 font-weight-bold">Carbon report</h3>
      <div className="nav nav-tabs" role="tablist">
        {tabLabels}
      </div>
      <ErrorBoundary>
        <div className="tab-content">{tabContents}</div>
      </ErrorBoundary>
    </div>
  );
};

function ReportComponent(props) {
  let { categoryTrees, sectionsComplete, disableInputs, inputsByCategoryPath, sortKey } = props;
  if (!categoryTrees || categoryTrees.length === 0) {
    return null;
  }

  const modalId = "ReportComponentModal";

  let tabDetails = categoryTrees
    .filter((i) => i)
    .sortBy((i) => i.get(sortKey || "name"))
    .map((tree, i) => {
      let name = tree.get("name");
      let categoryPath = tree.get("_path");
      let _id = `path_${categoryPath}`;
      let isSectionComplete = sectionsComplete.get(categoryPath) === true;
      let hasInputs = pathHasInputs(categoryPath, inputsByCategoryPath);
      return { name, _id, tree, categoryPath, isSectionComplete, hasInputs };
    });

  let tabLabels = tabDetails.map((details, i) => (
    <CategoryTabLabel key={i} {...details} disableInputs={disableInputs} show={i == 0} />
  ));

  let tabContents = tabDetails.map((details, i) => (
    <CategoryTabContent key={i} index={i} {...props} {...details} tabDetails={tabDetails} show={i == 0} />
  ));

  const metaComplete = !tabLabels.map((tab) => tab?.props?.isSectionComplete).includes(false);

  if (is_sbcc) return <CarbonReportModalBody tabLabels={tabLabels} tabContents={tabContents} />;

  return (
    <ErrorBoundary>
      <WizardModal
        hideClose={false}
        id={modalId}
        fullWidth={true}
        body={
          <ErrorBoundary>
            <div className="container-lg m-3">
              <button className="btn btn-link text-muted" onClick={() => $(`#${modalId}`).modal("hide")}>
                <p
                  className="text-muted text-underline mb-5"
                  style={{ cursor: "pointer", textDecoration: "underline" }}
                >
                  <Octicon name="chevron-left" />
                  Back to main reporting page
                </p>
              </button>
              <CarbonReportModalBody tabLabels={tabLabels} tabContents={tabContents} />
              <BSBtn
                // noFloatRight={true}
                // primaryOff={true}
                extra_className={"my-3 btn-primary"}
                text={"Done"}
                onClick={() => $(`#${modalId}`).modal("hide")}
              />
            </div>
          </ErrorBoundary>
        }
      />
      <div className="container shadow rounded my-4">
        <div className="row p-3 m-3 justify-content-between">
          <div className="row">
            <h5 className="h5">Step 3/4</h5>
            <span className={`text-muted align-text-bottom mt-1 ml-2 `}>
              <Tooltip html={metaComplete ? "Complete" : "Not complete"} animateFill={false}>
                <Octicon
                  name={metaComplete ? "verified" : "unverified"}
                  className={`${metaComplete ? "text-success" : "text-warning"}`}
                />
              </Tooltip>
              <span className={`text-muted align-text-bottom mt-1 ml-2`}>Emissions data</span>
            </span>
          </div>

          <button
            className={`btn btn-sm font-weight-bold ${metaComplete ? "btn-success" : "btn-warning"}`}
            onClick={() => $(`#${modalId}`).modal("show")}
          >
            <u>{metaComplete ? "Complete" : "To do"}</u>
          </button>
        </div>
      </div>
    </ErrorBoundary>
  );
}

function CategoryTableWrapper(props) {
  let { asFullTable, tree } = props;
  // If not rendering a full table then just render children directly
  if (!asFullTable) {
    return props.children;
  }
  // Wrap the children (<tr> elements) in a table
  return (
    <ErrorBoundary>
      <CategoryHelpText tree={tree} />
      <table className="table table-sm">
        <ReportTableHeader />
        <tbody>{props.children}</tbody>
      </table>
    </ErrorBoundary>
  );
}

function CategoryHelpText({ tree }) {
  let helpText = tree.getInPath("meta_json.helpText");
  if (!helpText) {
    return null;
  }
  return (
    <div className="alert alert-primary mt-2 mb-2" role="alert">
      {helpText}
    </div>
  );
}

function CategoryTable(props) {
  let { tree, inputErrors, disableInputs, updateError, asFullTable, hideTitleRow } = props;

  // Pass through only validation errors for this issue/category
  let path = tree.get("_path");
  let _inputErrors = (inputErrors || Traec.Im.Map()).get(path);

  // Clean up the props to avoid passing unneccessary stuff that will cause a category to update (for performance)
  let _props = { ...props };
  let keysToRemove = [
    "tabDetails",
    "inputErrors",
    "reportScores",
    "scoreValues",
    "inputsByCategoryPath",
    "metricScores",
  ];
  for (let key of keysToRemove) {
    delete _props[key];
  }

  return (
    <CategoryTableWrapper asFullTable={asFullTable} tree={tree}>
      <ReportCategoryItem
        {..._props}
        tree={tree}
        path={path}
        categoryPath={path} // Keep a record of the category/issue path (for section complete and validation)
        inputErrors={_inputErrors}
        hideTitleRow={hideTitleRow}
        disableInputs={disableInputs}
        updateInputErrors={updateError}
      />
    </CategoryTableWrapper>
  );
}

function CategoryTableRows(props) {
  let { categoryTrees, sortKey } = props;

  if (!categoryTrees || categoryTrees.length === 0) {
    return null;
  }

  return categoryTrees
    .filter((i) => i)
    .sortBy((i) => i.get(sortKey || "name"))
    .map((tree, i) => (
      <ReportRowErrorBoundary
        key={i}
        msg={
          <td colSpan="100%" className="border-0">
            Error loading issue section: {tree.get("name")}
          </td>
        }
      >
        <CategoryTable {...props} tree={tree} hideTitleRow={false} asFullTable={false} />
      </ReportRowErrorBoundary>
    ));
}

function ReportTableHeader(props) {
  const titles = [
    { name: "Metric", width: "50%", className: "text-left" },
    { name: "Units", width: "5%", className: "text-left" },
    { name: "Value", width: "10%", className: "text-left" },
    { name: "Comments", width: "25%", className: "text-left" },
    { name: "N/A", width: "5%" },
    { name: "Past data", width: "5%", className: "text-nowrap" },
  ];
  const headCols = titles.map((title, i) => (
    <th
      key={i}
      width={title.width}
      className={`${title.className} border-top-0` || "text-center border-top-0"}
      scope="col"
    >
      {title.name}
    </th>
  ));
  return (
    <thead>
      <tr>{headCols}</tr>
    </thead>
  );
}

function TableReport(props) {
  let { commit, isLoading } = props;
  if (isLoading || !commit) {
    return null;
  }

  return (
    <table className="table table-sm table-hover">
      <ReportTableHeader />
      <tbody>
        <CategoryTableRows {...props} />
      </tbody>
    </table>
  );
}

function ReportCommitForm(props) {
  let {
    projectId,
    trackerId,
    refId,
    commitId,
    commit,
    isLoading,
    inputErrors,
    needsRevision,
    projectReportingPeriods,
    revalidate,
  } = props;

  if (isLoading) {
    return null;
  }

  // Get the fetch that will be used
  let method = needsRevision() ? "patch" : "post";
  let fetch = new Traec.Fetch("tracker_ref_commit", method, { trackerId, refId, commitId });
  let postSubmitUrl = refId ? `/project/${projectId}/wpack/${refId}/evals` : `/project/${projectId}/`;
  fetch.updateFetchParams({
    preFetchHook: (body) => (method == "patch" ? { ...body, status: "UPDATE" } : body),
    postSuccessHook: (data) => {
      let rpId = data.reporting_period;
      let rp = projectReportingPeriods ? projectReportingPeriods.get(rpId) : null;
      let rpStr = rp ? ` for ${renderReportPeriod(rp)}` : null;
      let methodStr = method == "post" ? "submitted" : "updated";
      alertSuccess({
        text: `Thank you for submitting your data${rpStr}.  Your report was successfully ${methodStr}.`,
        onConfirm: () => {
          location.href = postSubmitUrl;
        },
      });
    },
  });

  let placeholder_text = (commit || Traec.Im.Map()).getInPath("meta_json.submit_placeholder") || "Write a comment...";

  return (
    <ErrorBoundary title="Error loading submission button">
      <div className="mt-2 mb-5 row d-flex justify-content-center">
        <Submit
          {...props}
          revalidate={revalidate}
          needsRevision={needsRevision}
          inputErrors={inputErrors}
          buttonText="Submit"
          noFloatRight={true}
          submitBtnClasses="text-center mt-3"
        />
      </div>
      <div style={{ clear: "both" }} className="mb-2" />
    </ErrorBoundary>
  );
}

function ReportBody(props) {
  const { commit, project, tracker, convFactorDetails, scoreValues, commitNodes, hasConversionMap, company } = props;
  let loadingMessage = "Data loading, please wait a moment...";
  // Ensure we have everything we need to render the report
  if (
    !(
      commit &&
      project &&
      tracker &&
      convFactorDetails &&
      convFactorDetails.convFactorMap &&
      //scoreValues &&
      commitNodes &&
      hasConversionMap
    )
  ) {
    return <TipsSpinner message={loadingMessage} />;
  }

  // Get the format of the report to render (tabbed or classic table)
  let report_layout = commit.getInPath("meta_json.report_layout") || "classic";

  //console.log("CALLING render on ReportBody");
  return (
    <ErrorBoundary title="Error loading report">
      {/* Set defined meta-data input fields for Reporting period and report type of the form */}
      <ReportPeriodAndType {...props} />
      {/* Render the meta data input fields */}
      <MetaFieldsReportComponent {...props} />
      {/* Render the remainder of the carbon report form */}
      <ReportComponent {...props} />
      {/* Set defined meta-data input fields for Apportionment of the form */}
      <ApportionMetaModal {...props} />
      {/* Render the submit for approval panel */}
      <ReportCommitForm {...props} />
    </ErrorBoundary>
  );
}

export const isMetaComplete = (meta) => {
  if (!meta || meta?.get("showApportions") == true) {
    return false;
  }
  let apportions = meta?.get("apportions") || Traec.Im.List();
  //console.log("Checking if report meta complete", apportions?.toJS(), meta?.toJS());
  return apportions.size;
};

export const isApportionmentComplete = (meta) => {
  return (meta?.get("apportions")?.size || 0) > 0;
};

export const isAdditionalMetaComplete = (meta) => {
  if (!meta) {
    return false;
  }
  let input_details = meta?.get("input_details") || Traec.Im.Map();
  let fields = input_details.get("fields") || Traec.Im.List();
  //console.log("Checking if report meta complete", fields?.toJS(), meta?.toJS());
  return fields.every((i) => meta?.get(i?.get("header")));
};

const handleMetaComplete = (_meta, modalId) => {
  //console.log("Submitted meta-data form is complete.  Closing modal");
  $(`#${modalId}`).modal("hide");

  alertSuccess({
    title: "Company information complete",
    text: _text,
    iconType: "success",
    //onConfirm: _onConfirm,
    //onCancel: _onConfirm
  });
};

const setLiteReport = (e, props, isLiteReport) => {
  e.preventDefault();
  let { commit } = props;
  let liteReport = commit?.getInPath("meta_json.liteReport") || false;
  if (liteReport == isLiteReport) {
    return null;
  }

  confirmProceed({
    text: `This will change your current reporting structure and any data entered to this report (for this reporting period only) will be lost.  After this your browser will reload.  Are you sure you would like to proceed?`,
    onConfirm: () => {
      let { trackerId, refId, commitId } = props;
      let fetch = new Traec.Fetch("tracker_ref_commit", "patch", { trackerId, refId, commitId });
      fetch.updateFetchParams({
        body: {
          meta_json: {
            liteReport: isLiteReport,
            apportions: commit?.get("meta_json")?.get("apportions")
              ? commit?.get("meta_json")?.get("apportions")?.clear()?.toJS()
              : Im.List()?.toJS(),
          },
        },
        postSuccessHook: () => {
          location.reload();
        },
      });
      fetch.dispatch();
    },
  });
};

const ReportPeriodAndTypeModalBody = (props) => {
  let { disableInputs, setSelectedPeriod, commit, completeSection, hideApportionment } = props;
  let liteReport = commit?.getInPath("meta_json.liteReport");

  return (
    <>
      <div className="card shadow p-3 my-5 bg-white rounded border-0">
        <h3 className="my-3 p-2 font-weight-bold">Select a reporting period</h3>
        <p>
          Carbon reporting for period -{" "}
          <b>
            <ReportPeriodSelection {...props} />
          </b>
        </p>
      </div>
      {hideApportionment ? null : (
        <div className="card shadow p-3 my-5 bg-white rounded border-0">
          <h3 className="my-3 p-2 font-weight-bold">Select a report type</h3>
          <p>Do you already know your organisation’s carbon emissions broken down by scope in tCO2e?</p>
          <div className="form-check">
            <input
              disabled={(commit?.getInPath("status.name") || "").startsWith("OK")}
              type="checkbox"
              className="form-check-input"
              id="checkLiteReport"
              checked={liteReport}
              onChange={(e) => {
                setLiteReport(e, props, true);
                completeSection(true);
              }}
            />
            <label className="form-check-label" htmlFor="checkLiteReport">
              Yes
            </label>
            <Tooltip
              animateFill={false}
              html={`(e.g. for reporting to SECR or ESOS). You will be invited to report your Scope 1, 2 and 3 emissions.`}
            >
              <Octicon name="info" className="ml-2" />
            </Tooltip>
          </div>
          <div className="form-check">
            <input
              disabled={(commit?.getInPath("status.name") || "").startsWith("OK")}
              type="checkbox"
              className="form-check-input"
              id="checkFullReport"
              checked={!liteReport}
              onChange={(e) => {
                setLiteReport(e, props, false);
                completeSection(true);
              }}
            />
            <label className="form-check-label" htmlFor="checkFullReport">
              No
            </label>
            <Tooltip
              animateFill={false}
              html={`The Carbon Tool will calculate your carbon emissions based on the activity data that you provide

              broken down by: company transport, electricity, fossil fuels, fugitive emissions, materials, third party
              transport, waste and water. Not all sections are mandatory.`}
            >
              <Octicon name="info" className="ml-2" />
            </Tooltip>
          </div>
        </div>
      )}
    </>
  );
};

function ReportPeriodAndType(props) {
  let { commit, revalidate, hideApportionment, disableInputs, trackerId, refId, commitId } = props;

  let [selectedPeriod, setSelectedPeriod] = useState("");

  if (!commit) {
    return null;
  }

  const btnText = "Reporting period and report type";
  const modalId = "ReportMetaDataModal";
  const metaField = "reportPeriodAndTypeSelected";
  const metaComplete = commit?.get("meta_json")?.get("reportPeriodAndTypeSelected");
  const completeSection = (isComplete) => editMeta(metaField, isComplete, trackerId, refId, commitId);

  if (is_sbcc)
    return (
      <ReportPeriodAndTypeModalBody
        setSelectedPeriod={setSelectedPeriod}
        completeSection={completeSection}
        {...props}
      />
    );

  return (
    <ErrorBoundary>
      <WizardModal
        hideClose={false}
        id={modalId}
        fullWidth={true}
        body={
          <ErrorBoundary>
            <div className="container-lg m-3">
              <div className="container">
                <button className="btn btn-link text-muted" onClick={() => $(`#${modalId}`).modal("hide")}>
                  <p
                    className="text-muted text-underline mb-5"
                    style={{ cursor: "pointer", textDecoration: "underline" }}
                  >
                    <Octicon name="chevron-left" />
                    Back to main reporting page
                  </p>
                </button>
                <p className="text-muted">Remember to click Save after selecting your options</p>
                <ReportPeriodAndTypeModalBody
                  setSelectedPeriod={setSelectedPeriod}
                  completeSection={completeSection}
                  {...props}
                />
                <button
                  className="btn btn-sm btn-primary float-right"
                  onClick={(e) => {
                    e.preventDefault();
                    completeSection(true);
                    $(`#${modalId}`).modal("hide");
                  }}
                >
                  Save
                </button>
              </div>
            </div>
          </ErrorBoundary>
        }
      />
      <div className="container shadow rounded my-4">
        <div className="row p-3 m-3 justify-content-between">
          <div className="row">
            <h5 className="h5">Step 1/4</h5>
            <span className={`text-muted align-text-bottom mt-1 ml-2 `}>
              <Tooltip html={metaComplete ? "Complete" : "Not complete"} animateFill={false}>
                <Octicon
                  name={metaComplete ? "verified" : "unverified"}
                  className={`${metaComplete ? "text-success" : "text-warning"}`}
                />
              </Tooltip>
              <span className={`text-muted align-text-bottom mt-1 ml-2 `}>Reporting for: {selectedPeriod}</span>
            </span>
          </div>

          <button
            className={`btn btn-sm font-weight-bold ${metaComplete ? "btn-success" : "btn-warning"}`}
            onClick={(e) => $(`#${modalId}`).modal("show")}
          >
            <u>{metaComplete ? "Complete" : "To do"}</u>
          </button>
        </div>
      </div>
    </ErrorBoundary>
  );
}

const ApportionModalBody = ({ commit, revalidate, modalId, setClear, company }) => {
  const clearAndClose = () => {
    setClear(true);
    if (modalId) {
      $(`#${modalId}`).modal("hide");
    }
  };

  return (
    <div className="card shadow p-3 my-5 bg-white rounded border-0">
      <div className="row my-2 p-3">
        <h3 className="font-weight-bold">Apportion based on revenue</h3>
        <Tooltip
          animateFill={false}
          html={
            <p>
              As well as entering your activity data to calculate your company’s carbon emissions, we also want to
              apportion your carbon emissions to your clients and customers: your scope 1, 2 and 3 contribute to part of
              their scope 3 emissions.
              <br />
              We do this based on the proportion of your business that is delivered for each of your clients, by
              percentage of revenue. For example, 10% of your contracts, sales or orders are from Client A, and 20% from
              Client B, etc. You insert these percentages against your clients and customers in the list, and the Tool
              apportions your overall carbon emissions according to the percentage split you provide. Clients will never
              see your apportionment percentages.
            </p>
          }
        >
          <Octicon name="info" className="ml-2" />
        </Tooltip>
      </div>

      <SetMetaApportion
        companyId={company?.get("uid")}
        companyDomain={company?.get("domain")}
        saveButtonText="Complete apportionment"
        disabled={(commit?.getInPath("status.name") || "").startsWith("OK")}
        isStaging={commit.get("is_staging")}
        saveMetaFetchProps={{
          handler: "tracker_ref_commit",
          method: "patch",
          params: {
            trackerId: commit.get("tracker"),
            refId: commit.get("ref"),
            commitId: commit.get("uid"),
          },
          successHook: (data) => {
            if (revalidate) {
              revalidate();
            }
            let _meta = Traec.Im.fromJS(data || {})?.get("meta_json");
            //console.log("Validating saved meta-data", _meta?.toJS());
            if (isMetaComplete(_meta)) {
              //console.log("Submitted meta-data form is complete.  Closing modal");
              clearAndClose();
            }
          },
        }}
        metaJson={commit.get("meta_json")}
      />
    </div>
  );
};

function WizardModalBody({ clear, setClear, commit, revalidate, modalId, company }) {
  if (clear) {
    return null;
  }

  return (
    <ErrorBoundary>
      <div className="container-lg m-3">
        <div className="container">
          <button
            className="btn btn-link text-muted"
            onClick={() => {
              setClear(true);
              $(`#${modalId}`).modal("hide");
            }}
          >
            <p className="text-muted text-underline mb-5" style={{ cursor: "pointer", textDecoration: "underline" }}>
              <Octicon name="chevron-left" />
              Back to main reporting page
            </p>
          </button>
          <ApportionModalBody
            commit={commit}
            revalidate={revalidate}
            modalId={modalId}
            setClear={setClear}
            company={company}
          />
        </div>
      </div>
    </ErrorBoundary>
  );
}

function ApportionMetaModal(props) {
  let [clear, setClear] = useState(false);

  let { commit, revalidate, hideApportionment, company } = props;

  if (!commit || hideApportionment) {
    return null;
  }

  const modalId = "ApportionMetaModal";
  const metaComplete = isMetaComplete(commit?.get("meta_json"));

  if (is_sbcc) return <ApportionModalBody commit={commit} revalidate={revalidate} company={company} />;

  return (
    <ErrorBoundary>
      <WizardModal
        hideClose={false}
        id={modalId}
        fullWidth={true}
        body={<WizardModalBody {...{ clear, setClear, commit, revalidate, modalId, company }} />}
      />
      <div className="container shadow rounded my-4">
        <div className="row p-3 m-3 justify-content-between">
          <div className="row">
            <h5 className="h5">Step 4/4</h5>
            <span className={`text-muted align-text-bottom mt-1 ml-2 `}>
              <Tooltip html={metaComplete ? "Complete" : "Not complete"} animateFill={false}>
                <Octicon
                  name={metaComplete ? "verified" : "unverified"}
                  className={`${metaComplete ? "text-success" : "text-warning"}`}
                />
              </Tooltip>
              <span className={`text-muted align-text-bottom mt-1 ml-2`}>Report to clients</span>
            </span>
          </div>

          <button
            className={`btn btn-sm font-weight-bold ${metaComplete ? "btn-success" : "btn-warning"}`}
            onClick={() => {
              setClear(false);
              $(`#${modalId}`).modal("show");
            }}
          >
            <u>{metaComplete ? "Complete" : "To do"}</u>
          </button>
        </div>
      </div>
    </ErrorBoundary>
  );
}

export function ProjectNoReport({ companyId, location }) {
  let [confirmed, setConfirmed] = useState(false);
  let { state } = location;
  let next = state?.next || "/user/profile/";
  let text = state?.next ? "Go to my company dashboard" : "Go to my profile";
  return confirmed ? (
    <Redirect to={next} />
  ) : (
    <BSCard
      widthOffset={"mt-3"}
      title={"Reporting Complete"}
      body={
        <React.Fragment>
          <p>Your report has already been submitted for this year. You will be redirected to your company dashboard.</p>
          <button className="btn btn-success" onClick={() => setConfirmed(true)}>
            {text}
          </button>
        </React.Fragment>
      }
    />
  );
}

class ProjectReport extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      fetchedEdges: false,
      fetchedRootCommitBranches: false,
      fetchedConversionFactors: false,
      fetchedValues: false,
      fetchedUrls: {},
      showDocs: false,
      nameFormParams: {
        stateParams: {},
        fetchParams: {},
        initFields: {},
      },
      commitFormParams: {
        stateParams: {},
        fetchParams: {},
        initFields: {},
      },
      isLoading: true,
      disableInputs: false,
      inputErrors: Traec.Im.Map(),
      sectionsComplete: Traec.Im.Map(),
      madeConversionMap: false,
      doneInitialValidation: false,
      convFactorDetails: {},
    };

    this.requiredFetches = [
      new Traec.Fetch("project_reporting_periods", "list"),
      new Traec.Fetch("project_permission", "list"),
      new Traec.Fetch("project_discipline", "list"),
      new Traec.Fetch("tracker_ref_commit", "list"),
      new Traec.Fetch("tracker_node", "list"),
      new Traec.Fetch("tracker_ref", "read"),
      new Traec.Fetch("project_tracker", "list"),
      new Traec.Fetch("tracker_commit_value", "list"),
      new Traec.Fetch("tracker_commit_branch", "list"),
      new Traec.Fetch("tracker_commit_convfactor", "list"),
      new Traec.Fetch("tracker_branch", "list"),
      new Traec.Fetch("tracker_node", "list"),
      new Traec.Fetch("tracker_commit_convfactor", "list"),
      new Traec.Fetch("company", "read"),
      new Traec.Fetch("company", "list"),
      new Traec.Fetch("project", "read"),
      new Traec.Fetch("project", "list"),
    ];

    // action bindings
    this.updateError = this.updateError.bind(this);
    this.needsRevision = this.needsRevision.bind(this);
    this.updateSectionComplete = this.updateSectionComplete.bind(this);
    this.setSectionComplete = this.setSectionComplete.bind(this);
    this.doInitialValidation = this.doInitialValidation.bind(this);
    this.validateSectionComplete = this.validateSectionComplete.bind(this);
  }

  /**********************
    COMPONENT METHODS
  **********************/

  componentDidMount() {
    Traec.fetchRequiredFor(this);
  }

  componentDidUpdate(prevProps) {
    Traec.fetchRequiredFor(this);
    let {
      isResponsible,
      hasConversionMap,
      commitNodes,
      hasScoreValues,
      commitId,
      categoryTrees,
      conversionFactors,
      baseMetrics,
    } = this.props;

    let { disableInputs, madeConversionMap, doneInitialValidation, convFactorDetails } = this.state;

    this.setIsLoading();

    if (conversionFactors && commitId && convFactorDetails && !convFactorDetails.convFactorMap) {
      // Load the conversion factors into state
      let state = Traec.Im.Map()
        .setInPath(`entities.commitEdges.byId.${commitId}.conversionFactors`, conversionFactors)
        .setInPath(`entities.baseMetrics.byId`, baseMetrics);
      // Set conversion factor map in state to avoid re-calculating in mapStateToProps
      this.setState({
        convFactorDetails: {
          convFactRef: null,
          convFactorMap: loadConvFacts(state, commitId),
        },
      });
    }

    // Construct the initial conversion factor maps
    if (
      !madeConversionMap &&
      hasScoreValues &&
      commitNodes &&
      convFactorDetails &&
      convFactorDetails.convFactorMap &&
      !hasConversionMap
    ) {
      setInitialConversions(commitId, commitNodes, convFactorDetails);
      this.setState({
        madeConversionMap: true,
      });
    }

    // Do an initial validation of the form after loading the scoreValues
    if (!doneInitialValidation && categoryTrees && hasScoreValues && commitNodes) {
      this.doInitialValidation();
    }

    // If we are responsible and the form is hidden then turn it on
    let shouldHide = false;
    if (!isResponsible) {
      shouldHide = true;
    }
    // If we are on a past-commit then hide the inputs (unless we are revising it)
    if (this.isOnHold() || (!this.needsRevision() && this.props.match.params._commitId)) {
      shouldHide = true;
    }

    // Set the state based on the outcome
    if (disableInputs !== shouldHide) {
      this.setState({ disableInputs: shouldHide });
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    return (
      hasDiff(this.props, nextProps, "ProjectReport props") || hasDiff(this.state, nextState, "ProjectReport state")
    );
  }

  setIsLoading() {
    let { categoryTrees } = this.props;
    if (!categoryTrees || categoryTrees.length === 0) {
      return null;
    }
    if (categoryTrees && this.state.isLoading) {
      this.setState({ isLoading: false });
    }
  }

  isOnHold() {
    let { commit } = this.props;
    let commitStatus = getCommitStatus(commit);
    return commitStatus && commitStatus.startsWith("Not for");
  }

  needsRevision() {
    let { tracker, cref, isResponsible, commit } = this.props;
    let needsRevision = false;
    if (tracker && cref && commit) {
      needsRevision = isResponsible && commit?.getInPath("meta_json.status") === "pending_revision";
    }
    return needsRevision;
  }

  /**********************
    MENU METHODS
  **********************/

  getReportLayout() {
    let { commit } = this.props;
    return (commit ? commit?.getInPath("meta_json.report_layout") : "classic") || "classic";
  }

  doInitialValidation() {
    let { categoryTrees, dispatch, commit, revalidateCount } = this.props;
    let { disableInputs } = this.state;
    //console.log("Doing initial validation of loaded data for form", disableInputs);

    let sectionsComplete = Traec.Im.Map();
    let mustHaveInputs = this.getReportLayout() != "classic";

    for (let tree of categoryTrees.filter((i) => i)) {
      let categoryPath = tree.get("_path");
      // If we have disabled inputs then mark all sections as complete
      //console.log("Doing initial validation for category", tree.get("name"), categoryPath);
      if (disableInputs) {
        sectionsComplete = sectionsComplete.set(categoryPath, true);
      } else {
        sectionsComplete = this.updateSectionComplete(categoryPath, sectionsComplete, mustHaveInputs);
      }
    }

    //console.log("Done initial validation. Setting section complete", sectionsComplete.toJS());
    this.setState({
      doneInitialValidation: true,
      sectionsComplete,
    });

    //console.log("DISPATCHING REVALIDATE COUNT", revalidateCount);
    dispatch({
      type: "UI_SET_IN",
      payload: { revalidateCount: revalidateCount + 1 },
      stateParams: {
        itemPath: `reports.${commit.get("uid")}`,
      },
    });
  }

  updateSectionComplete(categoryPath, sectionsComplete = null, mustHaveInputs = false) {
    //console.log("Determining if section has all required fields complete", categoryPath);
    let { commitNodes, metricScores, baseMetrics, currentReportingPeriod, scoreValues } = this.props;
    sectionsComplete = sectionsComplete || this.state.sectionsComplete;

    if (localStorage.getItem(`complete_${categoryPath}`) == "true") {
      //console.log("Got section complete status from localStorage as true");
      return sectionsComplete.set(categoryPath, true);
    }

    // Always have a valid Map to avoid errors
    scoreValues = scoreValues || Traec.Im.Map();

    // Get the metricscores and baseMetricIds that are under this category/issue
    let categoryMetricScores = getChildMetricScores(categoryPath, commitNodes, metricScores);
    let baseMetricIds = Traec.Im.Set(categoryMetricScores.map((i) => i.getInPath("metric.uid") || i.get("metric")));

    // Get the input score values by BaseMetricId
    let categoryScoreValues = getScoreValuesByBaseMetric(scoreValues, baseMetricIds);

    let complete = false;
    if (mustHaveInputs && !categoryScoreValues.size) {
      complete = false;
    } else {
      // If we have inputs then check all required is submitted
      complete = isAllRequiredSubmitted(categoryScoreValues, categoryMetricScores, baseMetrics, currentReportingPeriod);
    }

    // Log status
    console.log(
      "Setting cateogory at path complete status",
      categoryPath,
      Traec.Im.isImmutable(complete) ? complete.toJS() : complete
    );
    return sectionsComplete.set(categoryPath, complete);
  }

  validateSectionComplete(categoryPath) {
    let { inputErrors } = this.state;
    let status = false;

    let errors = inputErrors.get(categoryPath);
    if (errors && errors.size) {
      // First check there are no outstanding input errors
      console.warn("Section has errors - cannot mark as complete", categoryPath, errors.size, errors.toJS());
      let error_lines = errors
        .map((value, key, i) => `${key}`)
        .toList()
        .join("\n");
      alert(`Please correct input errors:\n${error_lines}`);
      status = errors;
    } else {
      // Check that all required fields are complete
      status = Traec.Im.fromJS(this.updateSectionComplete(categoryPath).get(categoryPath));
      if (!(status === true)) {
        console.warn("Section has required fields to complete - cannot mark as complete", categoryPath, status.toJS());
        alert(`Please complete the mandatory fields:\n${status.join("\n")}`);
      }
    }
    return status;
  }

  setSectionComplete(categoryPath, value) {
    //console.log("Toggling section complete", categoryPath, value);
    let { sectionsComplete, inputErrors } = this.state;

    // If setting section as complete then do some validation first
    if (value === true) {
      value = this.validateSectionComplete(categoryPath);
    }

    let _sectionsComplete = sectionsComplete.set(categoryPath, value);
    //console.log("Setting section complete", _sectionsComplete?.toJS());
    this.setState({ sectionsComplete: _sectionsComplete });

    // Set the localStorage
    let _key = `complete_${categoryPath}`;
    console.log(`Setting local storage key: ${_key}`, value);
    localStorage.setItem(_key, value);
  }

  updateError(metricName, value, isRequiredInPeriod, categoryPath) {
    let { inputErrors } = this.state;
    let _categoryErrors = Traec.Im.Map();

    categoryPath = categoryPath || "";
    let categoryErrors = inputErrors.get(categoryPath) || Traec.Im.Map();

    if (typeof value === "number" || value == null) {
      _categoryErrors = categoryErrors.delete(metricName);
    } else {
      _categoryErrors = categoryErrors.set(metricName, Traec.Im.Map({ name: metricName, value: value }));
    }

    let _inputErrors = inputErrors.set(categoryPath, _categoryErrors);

    //console.log("Updating input errors for category", categoryPath);
    if (this.getReportLayout() == "classic") {
      this.setState({
        inputErrors: _inputErrors,
        //sectionsComplete: this.updateSectionComplete(categoryPath),
      });
    } else {
      this.setState({
        inputErrors: _inputErrors,
      });
    }
  }

  render() {
    const { commit, company, project, cref, isRootRef, currentReportingPeriod, projectReportingPeriods } = this.props;
    let { isLoading, inputErrors, sectionsComplete, disableInputs, convFactorDetails } = this.state;
    //console.log("CALLING render on ProjectReport", isLoading);

    //console.log("RENDERING PROJECT REPRORT WITH sectionsComplete", sectionsComplete.toJS());

    // if (!isRootRef && !isLoading && commit && project && cref && !commit.get("reporting_period")) {
    //   //
    //   let projectId = project.get("uid")?.substring(0, 8);
    //   let refId = cref.get("uid")?.substring(0, 8);
    //   let companyId = project.getInPath("company.uid")?.substring(0, 8);
    //   return (
    //     <Redirect
    //       to={{
    //         pathname: `/company/${companyId}/`, // `/project/${projectId}/wpack/${refId}/noreport/`,
    //         state: { next: `/company/${companyId}/` }
    //       }}
    //     />
    //   );
    // }

    // if (isLoading) {
    //   return (
    //     <Spinner
    //       title="Loading..."
    //       explanation={"Loading report..."}
    //       timedOutComment={"Still loading, please be patient..."}
    //     />
    //   );
    // }

    return (
      <ErrorBoundary>
        {/*<h3>
          <ReportHeadline commit={commit} disableInputs={disableInputs} />
        </h3>
        <BreadCrumb company={company} project={project} cref={cref} isRootRef={isRootRef} />
        */}
        {is_sbcc ? (
          <div className="mt-5"></div>
        ) : (
          <div className="container p-0">
            <div
              className="jumbotron jumbotron-fluid text-white rounded text-center my-5 pb-4"
              style={{ backgroundColor: "#337ab7" }}
            >
              <h3 className="display-5 font-weight-bold text-white">
                <b>
                  Carbon calculator report
                  {/* <ReportPeriodYear reportingPeriod={currentReportingPeriod} /> */}
                </b>
              </h3>
              <ul style={{ listStyle: "none" }}>
                <li className="mt-3 text-white">
                  <p className="text-white">
                    Please complete the steps below. The submit button will be clickable when the steps are completed.
                  </p>
                </li>
              </ul>
            </div>
          </div>
        )}

        {/* Render the loading spinning wheel */}
        {isLoading ? (
          <TipsSpinner message="Please wait while we prepare your report." />
        ) : (
          <ReportBody
            {...this.props}
            inputErrors={inputErrors}
            needsRevision={this.needsRevision}
            updateError={this.updateError}
            disableInputs={disableInputs}
            sectionsComplete={sectionsComplete}
            setSectionComplete={this.setSectionComplete}
            convFactorDetails={convFactorDetails}
            revalidate={this.doInitialValidation}
          />
        )}
      </ErrorBoundary>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  let { _refId, _commitId } = ownProps.match.params;
  let { projectId, refId, commitId } = Traec.utils.getFullIds(state, ownProps.match.params);

  let { company, project, tracker, trackerId, cref, crefId, rootRef, isRootRef } = getProjectProps(
    state,
    projectId,
    refId
  );
  cref = refId?.startsWith(_refId) ? cref : null;
  if (!refId && !cref) {
    crefId = _refId;
  }

  // Get the commit data from the ref (or from flattened commitId)
  let commit = _commitId ? state.getInPath(`entities.commits.byId.${commitId}`) : cref?.get("latest_commit");
  commitId = commit?.get("uid");

  // Get the root tree to start with
  let rootTree = commit ? commit.get("tree_root") : null;
  let rootTreeId = rootTree ? rootTree.get("uid") : null;

  // Get all of the trees that are children of the rootTree (edges)
  let commitNodes = commitId ? state.getInPath(`entities.commitNodes.${commitId}`) : null;

  // To force the component to reload when the re-validate button is pressed
  let revalidateCount = state.getInPath(`ui.reports.${commitId}.revalidateCount`) || 0;
  //console.log("EXECUTING mapStateToProps FOR ProjectReport", revalidateCount);

  // Get the root children
  let pathRoot = null;
  let categoryTrees = null;
  if (commitNodes) {
    pathRoot = commitNodes.get("pathRoot");
    categoryTrees = getPathChildren(state, pathRoot, commitNodes, "trees").filter((i) => i);
  }

  // Load existing values input for this commit
  let scoreValues = commitId ? state.getInPath(`entities.commitEdges.byId.${commitId}.bmScoreValues`) : null;
  let hasScoreValues = state.getInPath(`entities.commitEdges.byId.${commitId}.bmScoreValues`) != null;

  // Get metric scores from Redux (filter down to only the metric scores used in this Report)
  let metricScores = state.getInPath("entities.metricScores.byId") || Traec.Im.Map();
  let reportMetricScoreIds = Traec.Im.Set(
    ((commitNodes || Traec.Im.Map()).get("byPath") || Traec.Im.Map())
      .filter((i) => i.get("type") == "metricscore")
      .toList()
      .map((i) => i.get("uid"))
  );
  metricScores = metricScores.filter((value, id) => reportMetricScoreIds.has(id));

  // Get BaseMetrics
  let conversionFactors = state.getInPath(`entities.commitEdges.byId.${commitId}.conversionFactors`);
  let baseMetrics = state.getInPath("entities.baseMetrics.byId");

  // Get inputs for each Category
  let categoryPathMap = (categoryTrees || Traec.Im.List()).reduce(
    (acc, cur) => acc.set(cur.get("_path"), cur),
    Traec.Im.Map()
  );
  let inputsByCategoryPath = categoryPathMap.map((tree, categoryPath) => {
    let categoryMetricScores = getChildMetricScores(categoryPath, commitNodes, metricScores);
    return categoryMetricScores
      .map((ms) => state.getInPath(`entities.commitEdges.byId.${commitId}.scoreValues.${ms.get("uid")}`))
      .filter((i) => i);
  });

  // Get the project reporting periods
  let projectReportingPeriods = crefId
    ? state.getInPath(`entities.projectReportingPeriods.ref.${crefId}.byId.${projectId}`)
    : null;
  if (!projectReportingPeriods) {
    projectReportingPeriods = state.getInPath(`entities.projectReportingPeriods.byId.${projectId}`);
  }

  let currentReportingPeriodId = commit ? commit.get("reporting_period") : null;
  let currentReportingPeriod = projectReportingPeriods ? projectReportingPeriods.get(currentReportingPeriodId) : null;

  // Get a map of Project Disciplines
  let projectDisciplines = state.getInPath(`entities.projectObjects.byId.${projectId}.disciplines`) || Traec.Im.List();
  let projectBaseDisciplineMap = projectDisciplines.reduce((obj, i) => obj.set(i.get("base_uid"), i), Traec.Im.Map());

  // ONLY ALLOW RESPONSIBLE PEOPLE OF THIS REPORT
  // Get the project permissions for this user
  let userProjectPermissions =
    state.getInPath(`entities.projectObjects.byId.${projectId}.userPermission`) || Traec.Im.Map();
  let isResponsible = userProjectPermissions.get("is_admin");

  if (commit && !isResponsible) {
    let responsibleDiscipline = commit.get("discipline");
    let userDisciplines = userProjectPermissions.get("baseDisciplineIds") || Traec.Im.List();
    isResponsible = userDisciplines.contains(responsibleDiscipline);
  }

  // Get the initial conversion map (to avoid commponents hitting the server on the first page load)
  let hasConversionMap = state.getInPath(`ui.childConversions.${commitId}`) != null;

  // sort alphabetically by default
  let sortKey = commit ? commit?.getInPath("meta_json.sortKey") || "name" : "name";

  // Should we hide the apportionment feature
  let hideApportionment = state.getInPath("entities.tenant.meta_json.hide_apportionment") == true;
  let submitSuccessMsg = state.getInPath("entities.tenant.meta_json.submit_success_msg");

  // Add this to props
  return {
    projectId,
    project,
    company,
    trackerId,
    tracker,
    cref,
    refId: crefId,
    commit,
    commitId,
    isRootRef,
    rootTree,
    rootTreeId,
    rootTreePath: pathRoot,
    commitNodes,
    categoryTrees,
    scoreValues,
    hasScoreValues,
    metricScores,
    baseMetrics,
    projectReportingPeriods,
    currentReportingPeriod,
    userProjectPermissions,
    isResponsible,
    trackerRootRef: rootRef,
    projectDisciplines,
    projectBaseDisciplineMap,
    conversionFactors,
    hasConversionMap,
    inputsByCategoryPath,
    revalidateCount,
    hideApportionment,
    submitSuccessMsg,
    sortKey,
  };
};

export default connect(mapStateToProps)(ProjectReport);
