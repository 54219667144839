import React, { useState, useEffect, useMemo } from "react";
import Im from "immutable";
import DataTable from "react-data-table-component";
import Octicon from "react-octicon";
import useApi, { useApis, usePostCache } from "storybook-dashboard/utils/fetching";
import { roundDecimals } from "storybook-dashboard/dashboard/charts/utils";
import { YearSelector, useYearSelector } from "storybook-dashboard/components/yearRangePicker";
import { getTenantConfig, getIndicatorUrl, ColumnName, columnHeader, getUnits, tableCustomStyles } from "./utils";
import Papa from "papaparse";
import { Link } from "react-router-dom";
import { SupplierLink } from "../../company/supplierReport";
import { INDUSTRY_DASHBOARD_ID } from "../../company/reportingMembers/engagementReport";

const generateAndDownloadCSV = (rowData) => {
  let headers = [
    "Supplier name",
    `Total emissions (tCO2e) / ${getUnits()}1m turnover`,
    "Total suppliers emissions (tCO2e) - scopes 1, 2 & 3",
    "Total suppliers emissions (tCO2e) - scopes 1 & 2 only",
    "Scope 1 emissions (tCO2e)",
    "Scope 2 emissions (tCO2e) - location based",
    "Scope 3 emissions (tCO2e)",
    `Turnover (${getUnits()})`,
  ];

  let keys = [
    "name",
    "turnoverPerMilId",
    "totalEmissions",
    "totalEmissionsNoScope3",
    "scopeOne",
    "scopeTwo",
    "scopeThree",
    "turnover",
  ];

  let data = rowData.map((row) => keys.map((k) => row[k]));
  let csv = Papa.unparse({ fields: headers, data });

  const link = document.createElement("a");
  const filename = "supplier_breakdown.csv";

  if (!csv.match(/^data:text\/csv/i)) {
    csv = `data:text/csv;charset=utf-8,${csv}`;
  }

  link.setAttribute("href", encodeURI(csv));
  link.setAttribute("download", filename);
  link.click();
};

// Get the data for the tenant we are on
const requiredData = getTenantConfig();

const formatValue = (value, currency = false) => {
  return value ? `${currency ? getUnits() : ""}${roundDecimals(value)}` : "";
};

const isNumber = (v) => !isNaN(parseFloat(v));

const sumEmissions = (item) => {
  let scopes = ["scopeOne", "scopeTwo", "scopeThree"].map((key) => item.get(key));
  return scopes.reduce((a, c) => (isNumber(c) ? a + c : a), null);
};

const sumEmissionsNoScope3 = (item) => {
  let scopes = ["scopeOne", "scopeTwo"].map((key) => item.get(key));
  return scopes.reduce((a, c) => (isNumber(c) ? a + c : a), null);
};

const turnoverPerMilNoScope3 = (item) => {
  let turnover = item?.get("turnover");
  let turnoverPerMil = isNumber(turnover) ? turnover / 1000000 : null;
  let totalEmissionsNoScope3 = sumEmissionsNoScope3(item);
  let emissionsPerMilSpentNoScope3 =
    isNumber(totalEmissionsNoScope3) && isNumber(turnoverPerMil) ? totalEmissionsNoScope3 / turnoverPerMil : null;

  return emissionsPerMilSpentNoScope3;
};

const mapSupplierToValue = (indicatorData, key = "indicator_result") => {
  return indicatorData?.reduce((acc, cur) => {
    return acc?.set(cur?.get("name"), cur?.get(key));
  }, Im.Map());
};

export const SupplierBreakdownTable = ({ projectId }) => {
  const [includeScope3, setIncludeScope3] = useState(true);
  const [filterByName, setFilterByName] = useState("");
  const [selectedSector, setSelectedSector] = useState("");
  const [availableSectors, setAvailableSectors] = useState(Im.List());
  const yearSelectorProps = useYearSelector("All time");
  const { dateRange } = yearSelectorProps;

  const year = dateRange.get("from_date") && dateRange.get("to_date") && Number(dateRange.get("from_date").slice(0, 4));
  const fetchFilters = {
    filters: {
      commit__reporting_period__endDate__gt: dateRange.get("from_date") || "2018-01-01T00:00:00", //"2022-01-01T00:00:00",
      commit__reporting_period__endDate__lte:
        dateRange.get("to_date") || `${new Date().getFullYear() + 1}-01-01T00:00:00`, //"2023-01-01T00:00:00",
    },
  };

  let _fetches = requiredData
    ?.map((value) => {
      let getUrlFunction = value.get("getUrl");
      let _id = value.get("id");
      return usePostCache(getUrlFunction(projectId, _id), fetchFilters);
    })
    ?.toJS();

  let { fetches, isLoading } = useApis(_fetches);

  // Initial reshaping of the data
  let data = requiredData?.map((value, key) => {
    let isIndicator = value.get("getUrl") == getIndicatorUrl;
    let _data = fetches[key].data;
    let imData = Im.fromJS(isIndicator ? _data?.refs : _data);
    return mapSupplierToValue(imData, isIndicator ? "indicator_result" : "value");
  });

  const supplierRefIds = fetches?.scopeOne?.data?.refs?.reduce((acc, cur) => {
    return acc?.set(cur.name, cur.uid);
  }, Im.Map());

  let dataByClient = data?.reduce((acc, clientsData, key) => {
    clientsData?.map((value, name) => {
      let data = acc.get(name) || Im.Map({ name });
      acc = acc.set(name, data.set(key, value));
    });
    return acc;
  }, Im.Map());

  const dataWithSupplierRefIds = dataByClient.mergeDeepWith((oldVal, newVal, key) => {
    return oldVal.set("uid", supplierRefIds.get(key));
  }, supplierRefIds);

  // fetch the reported suppliers company Ids
  const { data: clientReportStatus } = useApi(`/api/dashboard/project/${projectId}/reporting_package/status`, {
    projectId,
  });

  const supplierCompanyIds = clientReportStatus?.map((report) => report?.getIn(["source", "company_id"]));

  // fetch the sectors of reported suppliers
  const { data: supplierSectors } = usePostCache(
    `/api/dashboard/company/${INDUSTRY_DASHBOARD_ID}/report/query`,
    (data = {
      filters: {
        tracker__project__company__pk__in: supplierCompanyIds,
      },
      fields: ["meta_json__Please select your sector(s)", "tracker__project__company__pk"],
    })
  );

  const mapSectorsToSuppliers = useMemo(() => {
    // if the
    if (!supplierSectors) return Im.Map();

    return supplierSectors?.reduce((acc, curr) => {
      const supplierName = curr?.get("company_name");
      // the sectors data is either a List  or an empty string. Thus default to an empty List.
      const supplierSectorFromOneReport = curr?.get("meta_json__Please select your sector(s)") || Im.List();

      // make the supplier name the key in a new list
      if (!acc.has(supplierName)) {
        acc = acc.set(supplierName, Im.List());
      }

      const supplierSectorsFromAllReports = supplierSectorFromOneReport.reduce((allSectors, sector) => {
        return allSectors.includes(sector) ? allSectors : allSectors?.push(sector);
      }, acc?.get(supplierName));

      return acc.set(supplierName, supplierSectorsFromAllReports);
    }, Im.Map());
  }, [supplierSectors]);

  useEffect(() => {
    const uniqueSectors = mapSectorsToSuppliers?.reduce((acc, sectors) => {
      sectors?.forEach((sector) => {
        if (sector && !acc?.includes(sector)) {
          acc = acc?.push(sector);
        }
      });
      return acc;
    }, Im.List());

    setAvailableSectors(uniqueSectors);
  }, [mapSectorsToSuppliers]);

  const mergeSectorsWithSupplierData = (mapSectorsToSuppliers, dataWithSupplierRefIds) => {
    return dataWithSupplierRefIds?.map((supplierData, supplierName) => {
      return supplierData.set("sectors", mapSectorsToSuppliers?.get(supplierName) || Im.List());
    });
  };

  const dataWithSectorsAndRefIds = mergeSectorsWithSupplierData(mapSectorsToSuppliers, dataWithSupplierRefIds);

  let rowData = dataWithSectorsAndRefIds
    ?.toList()
    .map((i) =>
      i
        .set("totalEmissions", sumEmissions(i))
        .set("totalEmissionsNoScope3", sumEmissionsNoScope3(i))
        .set("turnoverPerMilNoScope3", turnoverPerMilNoScope3(i))
    )
    ?.toJS();

  const filteredRowData =
    rowData?.filter(
      (item) =>
        item &&
        item.name &&
        item.name.toLowerCase().includes(filterByName.toLowerCase()) &&
        (selectedSector === "" ||
          (item.sectors && Array.isArray(item.sectors) && item.sectors.includes(selectedSector))) &&
        (item.scopeOne || item.scopeTwo || item.scopeThree || item.turnover)
    ) || [];

  let allScopesColumns = [
    {
      name: <ColumnName title="Supplier name" />,
      selector: (item) => item.name,
      sortable: true,
      cell: (item) => (
        <Link to={`/project/${projectId}/wpack/${item?.uid?.substring(0, 8)}/evals`} className="link" target="_blank">
          <SupplierLink>{item.name}</SupplierLink>
        </Link>
      ),
      grow: 1,
      show: "always",
    },
    {
      name: <ColumnName title="Sector" />,
      selector: (item) => (item.sectors && item.sectors.length > 0 ? item.sectors.join(", ") : ""),
      sortable: true,
      show: "always",
      wrap: true,
      sortFunction: (rowA, rowB) => {
        if (!rowA.sectors?.length && rowB.sectors.length) return 1;
        if (rowA.sectors?.length && !rowB.sectors?.length) return -1;
        return (rowA.sectors?.join(", ") || "").localeCompare(rowB.sectors?.join(", ") || "");
      },
    },
    {
      name: <ColumnName title={`Total emissions (tCO2e) / ${getUnits()}1m turnover`} />,
      selector: (item) => item.turnoverPerMilId,
      format: (item) => formatValue(item.turnoverPerMilId),
      sortable: true,
      show: "scope3",
    },
    {
      name: <ColumnName title={`Scope 1 & 2 emissions (tCO2e) / ${getUnits()}1m turnover`} />,
      selector: (item) => item.turnoverPerMilId,
      format: (item) => formatValue(item.turnoverPerMilNoScope3),
      sortable: true,
      show: "scope1and2only",
    },
    {
      name: <ColumnName title="Total suppliers emissions (tCO2e) - scopes 1, 2 & 3" />,
      selector: (item) => item.totalEmissions,
      format: (item) => formatValue(item.totalEmissions),
      sortable: true,
      show: "scope3",
    },
    {
      name: <ColumnName title="Total suppliers emissions (tCO2e) - scopes 1 & 2 only" />,
      selector: (item) => item.totalEmissionsNoScope3,
      format: (item) => formatValue(item.totalEmissionsNoScope3),
      sortable: true,
      show: "scope1and2only",
    },
    {
      name: <ColumnName title="Scope 1 emissions (tCO2e)" />,
      selector: (item) => item.scopeOne,
      format: (item) => formatValue(item.scopeOne),
      sortable: true,
      show: "always",
    },

    {
      name: <ColumnName title="Scope 2 emissions (tCO2e) - location based" />,
      selector: (item) => item.scopeTwo,
      format: (item) => formatValue(item.scopeTwo),
      sortable: true,
      show: "always",
    },
    {
      name: <ColumnName title="Scope 3 emissions (tCO2e)" />,
      selector: (item) => item.scopeThree,
      format: (item) => formatValue(item.scopeThree),
      sortable: true,
      show: "scope3",
    },
    {
      name: <ColumnName title={`Turnover (${getUnits()})`} />,
      selector: (item) => item.turnover,
      format: (item) => formatValue(item.turnover, true),
      sortable: true,
      show: "always",
    },
  ];

  let columns = allScopesColumns.filter((col) => {
    if (includeScope3) return col.show == "always" || col.show == "scope3";
    return col.show == "always" || col.show == "scope1and2only";
  });

  const handleFilterChange = (e) => {
    setFilterByName(e.target.value);
  };

  const handleSectorChange = (e) => {
    setSelectedSector(e.target.value);
  };

  return (
    <div className="shadow col-sm-12 bg-white rounded my-3 p-3">
      <div className="d-flex flex-row flex-wrap justify-content-between align-items-center p-0 mb-3">
        <input
          className="col-sm-3 form-control form-control-sm"
          placeholder={`Filter by Supplier name`}
          onChange={handleFilterChange}
        />
        <select className="col-sm-2 form-control form-control-sm" value={selectedSector} onChange={handleSectorChange}>
          <option value="">All Sectors</option>
          {availableSectors
            .toJS()
            .sort()
            .map((sector) => (
              <option key={sector} value={sector}>
                {sector}
              </option>
            ))}
        </select>
        <div className="form-check d-flex align-items-center mr-2">
          <input
            className="form-check-input"
            type="checkbox"
            checked={includeScope3}
            onChange={(e) => {
              setIncludeScope3(e.target.checked);
            }}
            id="scope3SupplierBreakdown"
          />
          <label className="form-check-label" htmlFor="scope3SupplierBreakdown">
            Include scope 3 emissions
          </label>
        </div>
        <YearSelector {...yearSelectorProps} includeCustom={false} includeNone={true} />
        <button className="btn btn-link ml-2" onClick={() => generateAndDownloadCSV(rowData)}>
          <Octicon name="cloud-download" /> Export to csv
        </button>
      </div>
      <DataTable
        columns={columns}
        data={filteredRowData}
        fixedHeader={true}
        pagination={true}
        customStyles={tableCustomStyles}
      />
    </div>
  );
};

export default SupplierBreakdownTable;
