import React, { useState, useEffect } from "react";
import Traec from "traec";
import { confirmProceed } from "traec-react/utils/sweetalert";
import { MetaSaveButton } from "./meta";
import { ErrorBoundary } from "traec-react/errors";
import { saveMeta } from "./meta";
import { Tooltip } from "react-tippy";
import Im from "immutable";
import useApi from "storybook-dashboard/utils/fetching";

const getPartners = (setState) => {
  let fetch = new Traec.Fetch(
    "company_dispatch",
    "post",
    {},
    {
      preDispatchHook: (action) => {
        action.fetchParams.body = {
          type: "GET_MATCHING_META",
          payload: { isPartner: "1" },
        };
        action.stateParams.stateSetFunc = (state, action) => {
          //console.log("Got response data from GET_MATCHING_META", action);
          let companyList = Im.fromJS(action.payload?.payload || Im.List());
          let companyMap = companyList.reduce((acc, cur) => acc.set(cur.get("uid"), cur), Im.Map());
          setState(companyMap);
          return state;
        };
        return action;
      },
    }
  );
  return fetch;
};

function Apportions(props) {
  let { apportions } = props;
  return apportions.map((apportion, i) => <Apportion {...props} key={i} index={i} apportion={apportion} />);
}

function Apportion({ partners, apportion, index, apportions, setApportions, disabled }) {
  const [hideRemove, setHideRemove] = useState(false);
  let partnerId = apportion.get("companyId");
  let partner = partners.get(partnerId);

  useEffect(() => {
    if (apportions.size === 1) setHideRemove(true);
    else setHideRemove(false);
  }, [apportions.size]);

  return (
    <table className="table table-hover table-borderless">
      <tbody>
        <tr className="row py-1">
          <div className="col">{partner?.get("name")}</div>
          <div className="col">{apportion?.get("percent")} %</div>
          <div className="col">
            {disabled ? null : (
              <a
                className={`float-right text-danger ${hideRemove ? "text-muted" : ""}`}
                style={{ cursor: "pointer" }}
                onClick={(e) => {
                  e.preventDefault();
                  !hideRemove ? setApportions(apportions.delete(index)) : null;
                }}
              >
                <Tooltip
                  disabled={!hideRemove}
                  html={"You must have at least one client. Add another to remove this client."}
                  animateFill={false}
                >
                  remove
                </Tooltip>
              </a>
            )}
          </div>
        </tr>
      </tbody>
    </table>
  );
}

const ApportionRow = ({
  value,
  setValue,
  clientId,
  setClientId,
  partners,
  onAdd,
  options,
  disabled = false,
  errors = "",
}) => {
  return (
    <tbody>
      <tr className="row py-2">
        <div className="col-sm-4">
          <select
            className="form-control form-control-sm"
            value={clientId}
            onChange={(e) => setClientId(e.target.value)}
            disabled={disabled}
          >
            {options}
          </select>
        </div>
        <div className="col-sm-2">
          <div className="d-flex align-items-center">
            <input
              className="form-control form-control-sm"
              value={value}
              type="number"
              step="0.1"
              min="0"
              disabled={disabled}
              onChange={(e) => {
                let value = e.target.value;
                if (value.startsWith("0") && !(value[1] === ".")) {
                  value = value.slice(1);
                }
                setValue(value || "0");
              }}
            />
            <span className="ml-2">%</span>
          </div>
        </div>
        <div className="col">
          <button className="btn btn-primary btn-sm" onClick={onAdd} disabled={disabled}>
            Add
          </button>
        </div>
        {errors && (
          <div className="col-12 mt-1">
            <small className="text-danger">{errors}</small>
          </div>
        )}
      </tr>
    </tbody>
  );
};

const AddApportion = ({ partners, apportions, setApportions, hide, targettedByClients }) => {
  if (hide) return null;

  // state for Target client/partner dropdowns the user input values (only rendered if the supplier has been selected by a client as a Target supplier)
  const [targetClientValues, setTargetClientValues] = useState({});
  const [targetClientErrors, setTargetClientErrors] = useState({});

  // state for the regular client dropdown with all companies and user input
  const [value, setValue] = useState("0");
  const [clientId, setClientId] = useState("");
  const [errors, setErrors] = useState("");

  // create a Set of the IDs of the clients that have marked this supplier as a target
  const targettedByClientIds = new Im.Set(targettedByClients?.map((client) => client.get("dashboard_id")));

  // Target client/partner IDs that have been added by the user in the session
  const addedClientIds = new Im.Set(apportions.map((i) => i.get("companyId")));

  const options = (partners || Im.Map())
    .toList()
    .sortBy((partner) => partner?.get("name"))
    .map((partner, i) => (
      <option key={i} value={partner.get("uid")}>
        {partner.get("name")}
      </option>
    ))
    .unshift(<option key={-1} value=""></option>);

  const clientsToApportionTo = (partners || Im.Map())
    .toList()
    .sortBy((partner) => partner?.get("name"))
    // Filter for the clients that have marked the supplier as target
    .filter((partner, key) => targettedByClientIds.has(partner.get("uid").substr(0, 8)))
    // Filter out partners that have already been added by the user
    .filter((partner) => !addedClientIds.has(partner.get("uid")));

  const validateAndAdd = (partnerId, value) => {
    if (!partners.get(partnerId)) {
      return "Must select a valid partner";
    }
    const _value = parseFloat(value);
    if (!_value || _value <= 0 || _value > 100) {
      return "Must provide a percentage between 0 and 100";
    }
    if (addedClientIds.has(partnerId)) {
      return "Cannot add client twice";
    }
    return "";
  };

  const handleAdd = (partnerId, value) => {
    const error = validateAndAdd(partnerId, value);
    if (error) {
      if (targettedByClients?.size) {
        setTargetClientErrors((prev) => ({ ...prev, [partnerId]: error }));
      } else {
        setErrors(error);
      }
      return;
    }

    setApportions(
      apportions.push(
        Im.fromJS({
          companyId: partnerId,
          percent: parseFloat(value),
        })
      )
    );

    if (targettedByClients?.size) {
      // Clear the values and errors once  since is added
      setTargetClientValues((prev) => {
        const newValues = { ...prev };
        delete newValues[partnerId];
        return newValues;
      });
      setTargetClientErrors((prev) => {
        const newErrors = { ...prev };
        delete newErrors[partnerId];
        return newErrors;
      });
    } else {
      setClientId("");
      setValue("0");
      setErrors("");
    }
  };

  return (
    <table className="table table-hover table-borderless mb-4">
      {targettedByClients?.size ? (
        <>
          {clientsToApportionTo.map((partner) => {
            const partnerId = partner.get("uid");
            return (
              <ApportionRow
                key={partnerId}
                value={targetClientValues[partnerId] || "0"}
                setValue={(val) => setTargetClientValues((prev) => ({ ...prev, [partnerId]: val }))}
                clientId={partnerId}
                setClientId={() => {}}
                partners={partners}
                options={[
                  <option key={partnerId} value={partnerId}>
                    {partner.get("name")}
                  </option>,
                ]}
                onAdd={() => handleAdd(partnerId, targetClientValues[partnerId] || "0")}
                errors={targetClientErrors[partnerId]}
              />
            );
          })}
        </>
      ) : (
        <ApportionRow
          value={value}
          setValue={setValue}
          clientId={clientId}
          setClientId={setClientId}
          partners={partners}
          options={options}
          onAdd={() => handleAdd(clientId, value)}
          errors={errors}
        />
      )}
    </table>
  );
};

export function SetMetaApportion(props) {
  let {
    hideAdmin,
    hideSave,
    saveMetaFetchProps,
    pushMetaFetchProps,
    metaJson,
    saveButtonText,
    disabled,
    isStaging,
    modalId,
    company,
    companyDomain,
    companyId,
  } = props;

  // Ensure that we have something to start with for the meta-data
  metaJson = Im.fromJS(metaJson || Im.Map());
  //console.log("SetMetaApportion", Im.isImmutable(metaJson) ? metaJson.toJS() : metaJson);

  const { data: targettedByClients } = useApi(
    `/api/dashboard/company/${companyId}/${companyDomain}/supplier/targettedBy`
  );

  const [meta, setMeta] = useState(metaJson);
  const [state, setState] = useState({});
  const [partners, setPartners] = useState(Im.List());
  const [apportions, setApportions] = useState(metaJson.getInPath("apportions") || Im.List());
  const [pending, setPending] = useState(false);

  let total = apportions.reduce((a, c) => a + c.get("percent"), 0);
  hideSave = total > 100 || total < 0 || apportions.size == 0;

  useEffect(() => {
    Traec.fetchRequiredFor({
      props: {...props, companyId: null},
      state,
      setState,
      requiredFetches: [getPartners(setPartners)],
    });
  }, []);

  const saveApportions = () => {
    setPending(true);
    const setPendingAndRefresh = (value) => {
      setPending(value);
      if (!value) {
        location.reload();
      }
    };
    let _meta = Im.fromJS({
      apportions,
      showApportions: false,
    });
    saveMeta(saveMetaFetchProps, _meta.toJS(), setPendingAndRefresh);
    $(`#${modalId}`).modal("hide");
  };

  const onSaveHandler = (e) => {
    e.preventDefault();
    if (isStaging) {
      saveApportions();
    } else {
      confirmProceed({
        title: "Resubmit and approve report",
        text: "This will re-submit and approve the report and apportion data to clients.  Are you sure you would like to proceed?",
        onConfirm: () => saveApportions(),
      });
    }
  };

  const pushHandler = (e, fieldName, includeValue) => {
    e.preventDefault();
    pushMeta(pushMetaFetchProps, fieldName, includeValue);
  };

  return (
    <ErrorBoundary>
      <>
        {targettedByClients?.size > 0 && (
          <>
            <p className="text-muted">
              The following companies have selected you as their supplier. Add the percentage of your turnover they are
              responsible for.
            </p>
            <AddApportion
              hide={hideAdmin || disabled}
              targettedByClients={targettedByClients}
              partners={partners}
              apportions={apportions}
              setApportions={setApportions}
            />
            <br />
          </>
        )}
        <p className="text-muted">
          Select a partner from the dropdown options then enter the relevant percentage revenue to apportion them and
          click Add.
        </p>
        <AddApportion
          hide={hideAdmin || disabled}
          partners={partners}
          apportions={apportions}
          setApportions={setApportions}
        />
        <hr />
        <Apportions apportions={apportions} partners={partners} setApportions={setApportions} disabled={disabled} />
      </>
      <div className="my-3">
        {hideSave ? (
          <div className="row">
            <div className="col">
              <small id="errorText" className="form-text text-danger">
                The sum of apportionment percentages must be between 0 and 100%
              </small>
            </div>
          </div>
        ) : (
          <MetaSaveButton
            hide={hideSave}
            pending={pending}
            onSaveHandler={onSaveHandler}
            saveButtonText={saveButtonText}
            disabled={disabled}
          />
        )}
      </div>
    </ErrorBoundary>
  );
}
