import Im from "traec/immutable";
import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import Traec from "traec";

import { getProjectProps, BreadCrumb } from "AppSrc/project/utils";
import CommitTableRow from "./row";
import { BSBtnDropdown } from "traec-react/utils/bootstrap/btnDropdown";

function CommitTableRows(props) {
  let { commits } = props;
  commits = commits?.sortBy((commit) => commit.get("created")).reverse() || Traec.Im.List();
  return commits.map((commit, i) => <CommitTableRow key={i} commit={commit} {...props} />);
}

class WPCommits extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      hideNullReportingPeriod: true,
      fetchedLog: false,
      fetchedUrls: {},
      fetchedReportingPeriods: false,
      fetchedMembers: false,
      nameFormParams: {
        stateParams: {},
        fetchParams: {},
        initFields: {},
      },
    };

    this.requiredFetches = [
      //new Traec.Fetch('project_reporting_periods', 'list'),
      new Traec.Fetch("project_discipline", "list"),
      new Traec.Fetch("tracker_ref_commit", "list"),
      new Traec.Fetch("project_reporting_periods", "list"),
      new Traec.Fetch("tracker_ref", "read"),
    ];
  }

  componentDidMount() {
    Traec.fetchRequiredFor(this);
  }

  componentDidUpdate() {
    Traec.fetchRequiredFor(this);
  }

  getUrlParams() {
    const { cref, crefId } = this.props;
    if (!cref) {
      return {};
    }
    const trackerId = cref.get("tracker");
    const commitId = cref.get("latest_commit").get("uid");
    const refId = crefId;
    return { refId, commitId, trackerId };
  }

  render() {
    const { company, projectId, project, tracker, rootTree, cref, isRootRef, commits } = this.props;
    let { hideNullReportingPeriod: hideNRP } = this.state;
    if (!project || !tracker) {
      return null;
    }

    const titles = ["Reporting Period", "Status", "Last Updated", "Last Update By", "Comment", ""]; //"Supplier(s)" used to be first column
    const headCols = titles.map((title, i) => (
      <th key={i} scope="col">
        {title}
      </th>
    ));

    return (
      <React.Fragment>
        <h3>Reports submitted</h3>
        {/* <BreadCrumb company={company} project={project} cref={cref} isRootRef={isRootRef} /> */}
        <BSBtnDropdown
          links={[
            {
              name: `${hideNRP ? "Show" : "Hide"} invalidated reports`,
              onClick: () => {
                this.setState({ hideNullReportingPeriod: !hideNRP });
              },
            },
          ]}
        />
        {/* Render the headers of the table */}
        <table className="table table-sm">
          <thead>
            <tr>{headCols}</tr>
          </thead>
          {/* Render the rows */}
          <tbody>
            <CommitTableRows {...this.props} hideNullReportingPeriod={hideNRP} />
          </tbody>
        </table>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const { _projectId, _refId } = ownProps.match.params;
  let { projectId, refId } = Traec.utils.getFullIds(state, ownProps.match.params);

  // We need to do this because of removing the sidebar
  refId = refId || _refId;

  let { company, project, tracker, trackerId, cref, crefId, isRootRef } = getProjectProps(state, projectId, refId);
  // Get the commit history
  let commitIds = state.getInPath(`entities.categoryCommits.byId.${crefId}`);
  let path = crefId
    ? `entities.projectReportingPeriods.ref.${crefId}.byId.${projectId}`
    : `entities.projectReportingPeriods.byId.${projectId}`;
  let projectReportingPeriods = state.getInPath(path);

  let commits =
    commitIds
      ?.map((commitId) => {
        let commit = state.getInPath(`entities.commits.byId.${commitId}`);
        let reportPeriodId = commit.get("reporting_period");
        let reportPeriod = reportPeriodId
          ? commit.get("reporting_period_data") || projectReportingPeriods?.get(reportPeriodId)
          : null;
        return commit.set("reporting_period", reportPeriod);
      })
      ?.filter((i) => i)
      ?.filter((i) => i?.get("ref") == crefId)
      ?.sortBy((i) => i.getIn(["reporting_period", "startDate"])) || Im.List();

  // Get a map of Project Disciplines
  let projectDisciplines = state.getInPath(`entities.projectObjects.byId.${projectId}.disciplines`) || Im.List();
  let projectBaseDisciplineMap = projectDisciplines.reduce((obj, i) => obj.set(i.get("base_uid"), i), Im.Map());
  // Get the user project permission object
  let userPermission = state.getInPath(`entities.projectObjects.byId.${projectId}.userPermission`);
  // Add this to props
  return {
    userPermission,
    projectId,
    project,
    company,
    tracker,
    trackerId,
    cref,
    crefId,
    refId,
    isRootRef,
    commits,
    projectReportingPeriods,
    projectBaseDisciplineMap,
    projectDisciplines,
  };
};

export default connect(mapStateToProps)(WPCommits);
